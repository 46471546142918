export default function (lu, team, form, city, opponent, date) {
  const headCoach = team.coaches && team.coaches.find(f => f.role === 'Head Coach')
  const assCoach = team.coaches && team.coaches.find(f => f.role === 'Assistant Coach')

  const base = {
    Institution: team.name,
    Opponent: opponent ? opponent.name : null,
    Site: city,
    Date: date.format('dddd, MMMM Do YYYY'),
    'TEAM DUAL MATCH RECORD ENTERING THIS MATCH': null,
    'First NameNo 1': null,
    'Last NameNo 1': null,
    'Jersey NoNo 1': null,
    'Position Played Last MatchNo 1': null,
    'First Name1': null,
    'Last Name1': null,
    'Jersey No1': null,
    'Position Played Last Match1': null,
    'First NameNo 2': null,
    'Last NameNo 2': null,
    'Jersey NoNo 2': null,
    'Position Played Last MatchNo 2': null,
    'First Name2': null,
    'Last Name2': null,
    'Jersey No2': null,
    'Position Played Last Match2': null,
    'First NameNo 3': null,
    'Last NameNo 3': null,
    'Jersey NoNo 3': null,
    'Position Played Last MatchNo 3': null,
    'First Name3': null,
    'Last Name3': null,
    'Jersey No3': null,
    'Position Played Last Match3': null,
    'First NameNo 4': null,
    'Last NameNo 4': null,
    'Jersey NoNo 4': null,
    'Position Played Last MatchNo 4': null,
    'First Name4': null,
    'Last Name4': null,
    'Jersey No4': null,
    'Position Played Last Match4': null,
    'First NameNo 5': null,
    'Last NameNo 5': null,
    'Jersey NoNo 5': null,
    'Position Played Last MatchNo 5': null,
    'First Name5': null,
    'Last Name5': null,
    'Jersey No5': null,
    'Position Played Last Match5': null,
    'Jersey NoRow1': null,
    'First NameRow1': null,
    'Last NameRow1': null,
    'Jersey NoRow1_2': null,
    'First NameRow1_2': null,
    'Last NameRow1_2': null,
    'Pos Last PlayedRow1': null,
    'Date Last PlayedRow1': null,
    'Jersey NoRow2': null,
    'First NameRow2': null,
    'Last NameRow2': null,
    'Jersey NoRow2_2': null,
    'First NameRow2_2': null,
    'Last NameRow2_2': null,
    'Pos Last PlayedRow2': null,
    'Date Last PlayedRow2': null,
    'Jersey NoRow3': null,
    'First NameRow3': null,
    'Last NameRow3': null,
    'Jersey NoRow3_2': null,
    'First NameRow3_2': null,
    'Last NameRow3_2': null,
    'Pos Last PlayedRow3': null,
    'Date Last PlayedRow3': null,
    'Jersey NoRow4': null,
    'First NameRow4': null,
    'Last NameRow4': null,
    'Jersey NoRow4_2': null,
    'First NameRow4_2': null,
    'Last NameRow4_2': null,
    'Pos Last PlayedRow4': null,
    'Date Last PlayedRow4': null,
    'Jersey NoRow5': null,
    'First NameRow5': null,
    'Last NameRow5': null,
    'Jersey NoRow5_2': null,
    'First NameRow5_2': null,
    'Last NameRow5_2': null,
    'Pos Last PlayedRow5': null,
    'Match No Last PlayedRow5': null,
    'Date Last PlayedRow5': null,
    'Jersey NoRow6': null,
    'First NameRow6': null,
    'Last NameRow6': null,
    'Limited PositionRow6': null,
    'Jersey NoRow6_2': null,
    'First NameRow6_2': null,
    'Last NameRow6_2': null,
    'Pos Last PlayedRow6': null,
    'Match No Last PlayedRow6': null,
    'Date Last PlayedRow6': null,
    'Match No': null,
    'Limited PositionRow1': null,
    'Limited PositionRow2': null,
    'Limited PositionRow3': null,
    'Limited PositionRow4': null,
    'Limited PositionRow5': null,
    'Match No Last PlayedRow2': null,
    'Head Coach': headCoach ? headCoach.name : null,
    'Asst Coach': assCoach ? assCoach.name : null,
    'Third Coach': null,
    'HEAD COACH SIGNATURE': null,
    'Match No Last PlayedRow3': null,
    'Match No Last PlayedRow1': null,
    'Match No Last PlayedRow4': null
  }

  const getPlayer = (id) => {
    return team.players && team.players.find(f => f.id === id)
  }

  const setPlayerFields = (fields, player) => {
    base[fields[0]] = player.firstName
    base[fields[1]] = player.lastName
    base[fields[2]] = player.jersey
  }

  const pair1 = lu.starters.length > 0 && lu.starters[0]
  if (pair1) {
    setPlayerFields(['First NameNo 1', 'Last NameNo 1', 'Jersey NoNo 1'], getPlayer(pair1.players[0]))
    setPlayerFields(['First Name1', 'Last Name1', 'Jersey No1'], getPlayer(pair1.players[1]))
  }
  const pair2 = lu.starters.length > 0 && lu.starters[1]
  if (pair2) {
    setPlayerFields(['First NameNo 2', 'Last NameNo 2', 'Jersey NoNo 2'], getPlayer(pair2.players[0]))
    setPlayerFields(['First Name2', 'Last Name2', 'Jersey No2'], getPlayer(pair2.players[1]))
  }
  const pair3 = lu.starters.length > 0 && lu.starters[2]
  if (pair3) {
    setPlayerFields(['First NameNo 3', 'Last NameNo 3', 'Jersey NoNo 3'], getPlayer(pair3.players[0]))
    setPlayerFields(['First Name3', 'Last Name3', 'Jersey No3'], getPlayer(pair3.players[1]))
  }
  const pair4 = lu.starters.length > 0 && lu.starters[3]
  if (pair4) {
    setPlayerFields(['First NameNo 4', 'Last NameNo 4', 'Jersey NoNo 4'], getPlayer(pair4.players[0]))
    setPlayerFields(['First Name4', 'Last Name4', 'Jersey No4'], getPlayer(pair4.players[1]))
  }
  const pair5 = lu.starters.length > 0 && lu.starters[4]
  if (pair5) {
    setPlayerFields(['First NameNo 5', 'Last NameNo 5', 'Jersey NoNo 5'], getPlayer(pair5.players[0]))
    setPlayerFields(['First Name5', 'Last Name5', 'Jersey No5'], getPlayer(pair5.players[1]))
  }

  [1, 2, 3, 4, 5, 6].forEach(i => {
    const a = lu.alternates && lu.alternates.length >= 1 && lu.alternates[i - 1]
    if (a) {
      setPlayerFields([`First NameRow${i}`, `Last NameRow${i}`, `Jersey NoRow${i}`], getPlayer(a.players[0]))
    }
    const r = lu.removedPlayers && lu.removedPlayers.length >= 1 && lu.removedPlayers[i - 1]
    if (r) {
      setPlayerFields([`First NameRow${i}_2`, `Last NameRow${i}_2`, `Jersey NoRow${i}_2`], getPlayer(r.players[0]))
    }
  })

  for (const field in base) {
    const val = base[field]
    if (val) {
      // console.log(val)
      const ff = form.getTextField(field)
      ff.setText(val)
    }
  }

  return form
}
