<template>
  <v-btn
    color="color3 color3Text--text"
    :fab="fab"
    :text="!fab"
    :small="fab"
    :x-small="!fab"
    :loading="loading"
    @click="onClick"
  >
    <v-icon v-if="fab">fas fa-print</v-icon>
    {{ fab ? null : label }}
  </v-btn>
</template>

<script>
import { PDFDocument } from 'pdf-lib'
import printJS from 'print-js'
import MapLineupToNcaaForm from '@/helpers/MapLineupToNcaaForm'

export default {
  props: ['homeLineup', 'homeTeam', 'awayLineup', 'awayTeam', 'fab', 'date', 'location'],
  data () {
    return {
      pdfUrl: 'https://vblimages.blob.core.windows.net/files/NCBVB_RegSeasonLineupForm.pdf',
      loading: false
    }
  },
  computed: {
    label () {
      return this.awayLineup ? 'print lineups' : `print ${this.homeTeam.name}'s' lineup`
    }
  },
  methods: {
    async onClick () {
      console.log('print me')
      this.loading = true
      const formPdfBytes = await fetch(this.pdfUrl).then(res => res.arrayBuffer())
      let cityState = null
      await this.$VBL.getLocationCityState(this.location.id).then(r => { cityState = r.data })
      await this.$VBL.roster.coaches(this.homeTeam.rosteredTeamId).then(r => { this.homeTeam.coaches = r.data })
      if (this.awayTeam) {
        await this.$VBL.roster.coaches(this.awayTeam.rosteredTeamId).then(r => { this.awayTeam.coaches = r.data })
      }

      const pdfDoc = await PDFDocument.create()

      const page1Doc = await PDFDocument.load(formPdfBytes)
      const form = page1Doc.getForm()
      MapLineupToNcaaForm(this.homeLineup, this.homeTeam, form, cityState, this.awayTeam, this.date)
      await page1Doc.save()
      const [page1] = await pdfDoc.copyPages(page1Doc, [0])
      pdfDoc.addPage(page1)

      if (this.awayLineup && this.awayTeam) {
        const page2Doc = await PDFDocument.load(formPdfBytes)
        const form = page2Doc.getForm()
        MapLineupToNcaaForm(this.awayLineup, this.awayTeam, form, cityState, this.homeTeam, this.date)
        await page2Doc.save()
        const [page2] = await pdfDoc.copyPages(page2Doc, [0])
        pdfDoc.addPage(page2)
      }

      const pdfBytes = await pdfDoc.save()
      const file = new Blob([pdfBytes], { type: 'application/pdf' })
      this.loading = false
      // this.downloadFile(file, 'test.pdf')
      this.printFile(file)
    },
    downloadFile (blob, fileName) {
      const link = document.createElement('a')
      // create a blobURI pointing to our Blob
      link.href = URL.createObjectURL(blob)
      link.download = fileName
      // some browser needs the anchor to be in the doc
      document.body.append(link)
      link.click()
      link.remove()
      // in case the Blob uses a lot of memory
      setTimeout(() => URL.revokeObjectURL(link.href), 7000)
    },
    printFile (blob) {
      printJS(URL.createObjectURL(blob))
    }
  }
}
</script>
